$darkGrey: #a5a5a5;

* {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

#settings-page {
  height: calc(100vh - 10px);
  width: 100vw;
  display: grid;
  grid-template-columns: minmax(20px, auto) minmax(600px, 1000px) minmax(20px, auto);
  grid-template-rows: 10px auto 20px;
  grid-template-areas:
  ". . ."
  ". g ."
  ". . ."
}

#settings-content {
  grid-area: g;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: 100px 20px 20px 70px 20px auto 110px;
  grid-template-areas:
  "l l l l l l d d d d d d d d"
  "w w w w w w x x x x x x x x"
  ". . . . . . . . . . . . . ."
  "e e e z z z z z z z z z z z"
  ". . . . . . . . . . . . . ."
  "s s s m m m m m m m m m m m"
  "b b b . . . . . . . . . . ."
}

#contact-us-container {
  width: 500px;
  > textarea {
    width: 100%;
    height: 185px;
    padding: 15px;
    margin: 0 0 10px;
    font-size: 1rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  > div > button {
    width: 45%;
    height: 50px;
  }
}

#settings-message {
  grid-area: z;
}

#settings-logo-div {
  grid-area: l;
  border-right: 1px solid #a5a5a5;
  display: flex;
  align-items: flex-start;
}

#settings-doctor-div {
  grid-area: d;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

#settings-doctor-div h2 {
  margin-top: 0;
}

#under-logo-div {
  grid-area: w;
  border-bottom: 1px solid #a5a5a5;
  border-right: 1px solid #a5a5a5;
}

#under-doctor-div {
  grid-area: x;
  border-bottom: 1px solid #a5a5a5;
}

#settings-date-div {
  grid-area: e;
}

#settings-date-div > div > p {
  margin-top: 0;
}

#settings-date-div > div > h3 {
  margin-bottom: 0;
}

#settings-aside {
  grid-area: s;
  margin-right: 15px;
}

#settings-main {
  grid-area: m;
  margin-left: 20px;
}

#settings-back {
  margin-right: 15px;
  // grid-area: b;
  height: 50px;
  background-color: $darkGrey;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}

#onboard-back, .back-btn {
  // grid-area: b;
  height: 50px;
  background-color: $darkGrey;
  display: flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.back-btn {
  margin-top: 5px;
}

.testing-link-page {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: min-content auto;
  grid-template-areas:
  "x y"
  ". y"
}

.testing-link-container {
  grid-area: x;
  border: 1px solid black;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testing-link-container a {
  margin: 1em 0;
}

.payment-history-container {
  grid-area: y;
}

.payment-history-container > div:first-child {
  padding: 0 1em;
}

.flex-end {
  align-items: flex-end !important;
}

.black-border {
  border: 1px solid black;
}

.copy-link-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8ee9ff;
  width: 50%;
  transition: 0.3s;
  cursor: pointer;
}

.link-copied-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #a5a5a5;
  width: 50%;
}

.copied-span {
  display: flex;
  align-items: center;
}

.copy-link-btn:hover {
  background-color: #b4b5ff;
}

#onboard-back:hover, #settings-back:hover, .back-btn:hover {
  background-color: darken($darkGrey, 10%);
}

#settings-back > h3, .back-btn > h3 {
  color: white;
  margin-left: 20px;
}

.settings-img {
  height: 90px;
  width: auto;
  // margin-right: 20px;
}

.settings-logo {
  height: auto;
  width: 100px;
  margin-right: 10px;
}

#settings-logo-div h1 {
  margin-top: 0;
}

.settings-aside-div {
  // border: 1px solid black;
  height: 75px;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

h3 {
  font-weight: normal;
}

.settings-aside-div > h3 {
  margin-left: 20px;
}

#settings-main-row1, #settings-main-row2, #onboard-main-row {
  display: flex;
}

#settings-main-row2 > .third {
  width: calc(33% + 4px);
  display: flex;
  justify-content: flex-end;
}

#settings-main-row2 > .third > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

#per-patient > #onboard-main-row > .onboard-main-div {
  border-right: 2px solid #a5a5a5 !important;
}

#per-patient {
  display: flex;
  justify-content: center;
}

#per-patient > #onboard-main-row {
  width: 33% !important;
}

.carrot {
  cursor: pointer;
}

#per-patient > #onboard-main-row > .onboard-main-div {
  width: 100% !important;
}

#settings-main-row1 > .third {
  width: 33%;
  height: 100px;
  border: 2px solid #a5a5a5;
  border-right: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

#settings-main-row1 > .last-third, #onboard-main-row > .last-third {
  border: 2px solid #a5a5a5;
}

.third > * {
  margin: 0;
}

#settings-main-row2 > .last-third > div {
  background-color: #fef2f2 !important;
  cursor: pointer;
}

.third > div {
  width: 75%;
  background-color: #f4f6f5;
  height: 40px;

}

.third > div > p{
  margin: 0;

}

.green-text {
  color: rgb(6, 221, 115);
}

.selected-div {
  border-left: 10px solid black;
  border-top: 1px solid #a5a5a5!important;
  border-right: 1px solid #a5a5a5!important;
  border-bottom: 1px solid #a5a5a5 !important;
  width: 100%;
  -webkit-animation-name: selected-div;
  animation-name: selected-div;
  animation-duration: .3s;
  animation-fill-mode: forwards;
  // transform: translateX(-10px);
  // margin-right: 20px;
}

.margin-5 {
  margin: 5px;
}

.expand-content {
  animation-name: expand-content;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes expand-content {
  from {
    max-height: 0;
  }

  to {
    max-height: 100vh;
  }
}

.black-tab {
  width: 10px;
  height: 5px;
  background-color: black;
}

.shrink-content {
  animation-name: shrink-content;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes shrink-content {
  from {
    max-height: 100%;
  }

  to {
    max-height: 0;
  }
}

@keyframes selected-div {
  from {
    border-left-width: 1px;
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    transform: translateX(0px);
    margin-right: 0px;
  }
  to {
    border-left-width: 10px;
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    transform: translateX(-10px);
    margin-right: 20px;
  }
}

@-webkit-keyframes selected-div {
  from {
    border-left-width: 1px !important;
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    transform: translateX(0px);
    margin-right: 0px;
  }
  to {
    border-left-width: 10px;
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    transform: translateX(-10px);
    margin-right: 20px;
  }
}


#change-password-form > div > input {
  margin-bottom: 20px;
}

.back-link-container {
  grid-area: b;
}

.back-link {
  width: 100%;
  margin-right: 20px;
  color: white;
}

.back-link:first-child {
  margin-bottom: 10px;
}
